<!--
 * @Author: your name
 * @Date: 2022-06-27 10:43:55
 * @LastEditTime: 2022-06-30 17:35:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /website_6.17/src/views/NotFound.vue
-->
<template>
    <div class="err404" :style="{ height: back_height }">
        <h4>您访问的页面不见了！</h4>
        <h5>
            <router-link to="/" class="go-home">去首页</router-link>
        </h5>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'

export default {
    name: 'NotFound',
    setup(props,context) {
        context.emit('public_footer', true)
        context.emit('not_home',true)
        document.getElementById("co-driver").style.height = '100%'
        document.getElementsByTagName("html")[0].style.height = '100%'
        document.getElementsByTagName("body")[0].style.height = '100%'
        document.getElementsByClassName("el-footer")[0].style.transform = 'translateY(100%)'
        const back_height = document.body.offsetHeight * 0.49 + 'px'
        console.log(back_height)
        return {
            back_height
        }

    },

}
</script>

<style scoped>
.err404 {
    padding-top: 100px;
    /* height: 600px; */
    /* width: 280px;
    margin: 150px auto;
    height: 255px;
    padding: 180px 0 0 420px; */
    /* background: url(../../static/404_bg.png) no-repeat 0 0 */
}

.err404 h4 {
    margin-top: 50px;
    font-size: 24px;
    color: #191919;
    padding-bottom: 16px;
}

.err404 .go-home { 
    width: 130px;
    height: 38px;
    background: #00A0E9;
    line-height: 38px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    display: inline-block;
    border-radius: 2px
}
h4,h5{
    margin: 0.5rem;
}
a{
    text-decoration: none;
}
</style>